import { FC, Reducer, useReducer } from 'react';

import { AuthContext } from './AuthContext';
import { AuthContextActions, AuthContextProps, AuthState } from './AuthContext.types';
import { authReducer } from './AuthReducer';

export const AuthProvider: FC<AuthContextProps> = ({ children }) => {
  const [auth, dispatch] = useReducer<Reducer<AuthState, AuthContextActions>>(authReducer, {
    isLoading: false,
    isSuccess: false,
    userData: undefined,
    error: null,
  });

  return <AuthContext.Provider value={{ auth, dispatch }}>{children}</AuthContext.Provider>;
};
