import { useEffect, useState } from 'react';

const useLazyLoadScript = () => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (hasLoaded) {
      return;
    }

    const handleLazyLoad = () => {
      setHasLoaded(true);
      removeEventListeners();
    };

    const eventTypes = ['touchstart', 'scroll', 'keydown', 'mousemove', 'click'];
    const removeEventListeners = () => {
      eventTypes.forEach((event) => document.removeEventListener(event, handleLazyLoad));
    };

    eventTypes.forEach((event) => document.addEventListener(event, handleLazyLoad));

    // eslint-disable-next-line consistent-return
    return () => {
      removeEventListeners();
    };
  }, [hasLoaded]);

  return hasLoaded;
};

export default useLazyLoadScript;
