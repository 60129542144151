import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { hotjar } from 'react-hotjar';
import { AppProps } from 'next/app';
import { initializeApp } from 'firebase/app';
import { DehydratedState, Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createGlobalStyle } from 'styled-components';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';

import { ENV } from '@/config/env';
import { queryClient } from '@/config/queryClient';
import { AuthProvider } from '@/context/AuthContext';
import { ENVIRONMENTS } from '@/constants';

import 'nprogress/nprogress.css';
import '../styles/tailwind.css';
import useLazyLoadScript from '@/hooks/useLazyLoadScript';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const Normalize = createGlobalStyle`
  body, textarea, button {
    font-family: 'Roboto', sans-serif;
    padding-bottom: env(safe-area-inset-bottom);
  }

  button {
    background: unset;
    border: unset;
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }

  #nprogress .bar {
    background: rgba(75,197,136,1) !important;
    height: 4px;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    border-radius: 5px;
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    background: #aaa;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 5px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    z-index: 0;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .salfa {
    max-height: 100vh;
  }
`;

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

function MyApp({ Component, pageProps }: AppProps<{ dehydratedState: DehydratedState }>) {
  const router = useRouter();
  const lazyLoadScript = useLazyLoadScript();

  useEffect(() => {
    if (ENV.ENVIRONMENT === ENVIRONMENTS.PROD && !!ENV.HOTJAR.ID && !!ENV.HOTJAR.VERSION) {
      hotjar.initialize(+ENV.HOTJAR.ID, +ENV.HOTJAR.VERSION);
    }
  }, []);

  useEffect(() => {
    const pageview = (url: string) => {
      window.dataLayer = window.dataLayer || [];
      (window as any).dataLayer.push({
        event: 'pageview',
        page: url,
      });
    };

    router.events.on('routeChangeComplete', pageview);

    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const rebranding = window.localStorage.getItem('rebranding');

      if (rebranding !== 'true') {
        window.localStorage.setItem('rebranding', 'true');
        window.location.reload();
      }
    }
  }, []);

  initializeApp({
    apiKey: ENV.FIREBASE.API_KEY,
    authDomain: ENV.FIREBASE.AUTH_DOMAIN,
    projectId: ENV.FIREBASE.PROJECT_ID,
    storageBucket: ENV.FIREBASE.STORAGE_BUCKET,
    messagingSenderId: ENV.FIREBASE.MESSAGING_SENDER_ID,
    appId: ENV.FIREBASE.APP_ID,
  });
  if (typeof document === 'undefined') {
    React.useLayoutEffect = React.useEffect;
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href="/favicon.ico" sizes="48x48" />
        <link rel="icon" href="/favicon-svg.svg" sizes="any" type="image/svg+xml" />
        <link rel="icon" href="/favicon.png" type="image/png" />
        <link rel="apple-touch-icon" href="/favicon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, initial-scale=1.0, maximum-scale=1.0" />
        <meta name="google-site-verification" content="H4PzdRQRn0BLZncWx5JgQWzLwnlT-QgZ0jJAwP8Xb3Q" />
      </Head>
      {lazyLoadScript && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          defer
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${ENV.GTM_ID}');
          `,
          }}
        />
      )}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </Hydrate>
        <Normalize />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
