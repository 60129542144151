import { AuthContextActions, AuthState } from './AuthContext.types';

export const authReducer = (state: AuthState, action: AuthContextActions) => {
  switch (action.type) {
    case 'LOGOUT': {
      return {
        isLoading: false,
        isSuccess: true,
        userData: undefined,
        error: undefined,
      };
    }
    case 'SET_USER_DATA': {
      return {
        isLoading: false,
        isSuccess: true,
        userData: action.payload,
        error: null,
      };
    }
    default:
      return state;
  }
};
